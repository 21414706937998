<template>
  <v-sheet  class="fill-height d-flex align-center "
            :class="[
                { 'justify-center' : !element.config.display_icon && element.config.style !== 'text' },
                { 'justify-space-between' : element.config.display_icon && element.config.style !== 'text' }
            ]"
            :style="elementStyle"
            :color="buttonBackgroundColor"
  >
    <h5 :key="SCALE_COEFFICIENT" :style="BLOCK_TEXT_CSS(element , blockStyle , 'buttons' , 'text', colorVariable , null, null , DEFAULT_BLOCK_WIDTH)">
      <span v-if="element.config.action === 'enroll_on_course'">
        {{ $t('Enroll') }}
      </span>
      <span v-else>
        {{ element.text || $t('ButtonText')}}
      </span>
    </h5>
    <v-icon
        v-if="element.config.display_icon"
        :color="BLOCK_COLOR_PARAM(element, colorVariable , blockStyle )"
        class="ml-2"
    >
      {{ element.config.icon }}
    </v-icon>
  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "elementButtonEditor",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String
    }
  },
  data() {
    return {
      element : { config : {}},
    }
  },
  computed : {

    colorVariable() {
      return this.element.config.style === 'primary'
          ? 'button_text'
          : 'button_text_secondary'
    },
    buttonTextColor() {

      if ( this.blockStyle && this.element.config.style) {
          return this.CONFIG_PARAM_COLOR(
              'styles' ,
                    this.blockStyle ,
                    this.element.config.style === 'primary'
                      ? 'button_text'
                      : 'button_text_secondary'
          )
      }
      return this.element.config.text_color


    },
    buttonBackgroundColor() {
      if ( this.blockStyle && this.element.config.style) {
        if ( this.element.config.style === 'primary') {
          return this.BLOCK_COLOR_PARAM(this.element, 'button_bg' , this.blockStyle )
        } else {
          return 'transparent'
        }
      }
      return !this.element.config.outlined ? this.GET_AVALON_COLOR(this.element.config.background_color) : 'transparent'
    },
    elementStyle() {
      let style = ''

      if (this.element.config.outlined || this.element.config.style === 'secondary' ) {

        let color = this.blockStyle && this.element.config.style
            ? this.BLOCK_COLOR_PARAM(this.element , 'button_bg_secondary' , this.blockStyle )
            : this.GET_AVALON_COLOR(this.element.config.background_color)

        style += `border : 2px solid ${color} !important;`
      }
      if (this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      if ( this.element.config.display_icon && this.element.config.style !== 'text' ) {
        let paddingHorizontal = 8;

        if ( this.element.config.rounded && this.element.config.rounded > 8 ) {
          paddingHorizontal = this.element.config.rounded
        }

        style += `padding-left  : ${paddingHorizontal}px;`
        style += `padding-right : ${paddingHorizontal}px;`

      }

      return style
    }
  },
  watch : {
    value : {
      handler() {
        if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.element)) ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),

  },
  mounted(){
    if ( this.value ) {
      this.element = JSON.parse(JSON.stringify(this.value))
      this.$emit('input',this.element)
    }
  }
}
</script>

<style scoped>

</style>